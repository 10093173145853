import './index.css'
import { story } from './story.ts';

// Vertex shader
const vertexShaderSource = `
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;
varying highp vec2 vTextureCoord;
void main(void) {
    gl_Position = aVertexPosition;
    vTextureCoord = aTextureCoord;
}`;

// Fragment shader
const fragmentShaderSource = `
precision highp float;
varying highp vec2 vTextureCoord;
uniform float uTime;
uniform vec2 uResolution;
uniform sampler2D uTextTexture;

// Existing uniforms
uniform vec3 uBackgroundColor;
uniform float uScanlinePeriod;
uniform float uScanlineWidth;
uniform float uScanlineIntensity;

// New uniform for scanline fade
uniform float uScanlineFadeHeight;

const float CURVATURE = 6.0;
const float VIGNETTE_STRENGTH = 0.1;

vec2 curveRemapUV(vec2 uv) {
  uv = uv * 2.0 - 1.0;
  vec2 offset = abs(uv.yx) / vec2(CURVATURE, CURVATURE);
  uv = uv + uv * offset * offset;
  uv = uv * 0.5 + 0.5;
  return uv;
}

void main() {
  vec2 remappedUV = curveRemapUV(vTextureCoord);
    
  if (remappedUV.x < 0.0 || remappedUV.x > 1.0 || remappedUV.y < 0.0 || remappedUV.y > 1.0) {
    gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
    return;
  }
  
  vec4 texColor = texture2D(uTextTexture, remappedUV);
  
  // Apply background color
  vec3 color = mix(uBackgroundColor, texColor.rgb, texColor.a);
  
  // Updated scanline effect with fade
  float scanlinePosition = mod(uTime, uScanlinePeriod) / uScanlinePeriod;
  float distanceFromScanline = remappedUV.y - scanlinePosition;
  float scanlineEffect = 1.0 - smoothstep(0.0, uScanlineWidth, abs(distanceFromScanline));
  
  // Apply fade effect
  float fadeFactor = smoothstep(0.0, uScanlineFadeHeight, distanceFromScanline);
  scanlineEffect *= (1.0 - fadeFactor);
  
  if (distanceFromScanline < 0.025) {
    color += vec3(scanlineEffect * uScanlineIntensity);
  }

  // Vignette effect
  float vignette = length(remappedUV - 0.5) * VIGNETTE_STRENGTH;
  color -= vec3(vignette);
  
  gl_FragColor = vec4(color, 1.0);
}`;

class CRTEffectWebGL {
  private gl: WebGLRenderingContext;
  private program: WebGLProgram;
  private texture: WebGLTexture;

  constructor(private readonly canvas: HTMLCanvasElement) {
    this.gl = this.canvas.getContext("webgl2", { antialias: false })!;

    if (!this.gl) {
      alert("Unable to initialize WebGL. Your browser may not support it. Womp womp.");
      return;
    }

    this.program = this.createShaderProgram(vertexShaderSource, fragmentShaderSource);
    this.setupBuffers();
    this.texture = this.gl.createTexture()!;
  }

  private createShaderProgram(vertexShaderSource: string, fragmentShaderSource: string): WebGLProgram {
    const vertexShader = this.compileShader(this.gl.VERTEX_SHADER, vertexShaderSource);
    const fragmentShader = this.compileShader(this.gl.FRAGMENT_SHADER, fragmentShaderSource);

    const program = this.gl.createProgram()!;
    this.gl.attachShader(program, vertexShader);
    this.gl.attachShader(program, fragmentShader);
    this.gl.linkProgram(program);

    if (!this.gl.getProgramParameter(program, this.gl.LINK_STATUS)) {
      console.error("Unable to initialize the shader program: " + this.gl.getProgramInfoLog(program));
      return null!;
    }

    return program;
  }

  private compileShader(type: number, source: string): WebGLShader {
    const shader = this.gl.createShader(type)!;
    this.gl.shaderSource(shader, source);
    this.gl.compileShader(shader);

    if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
      console.error("An error occurred compiling the shaders: " + this.gl.getShaderInfoLog(shader));
      this.gl.deleteShader(shader);
      return null!;
    }

    return shader;
  }

  private setupBuffers(): void {
    const positionBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);
    const positions = [
      -1.0, 1.0,
      1.0, 1.0,
      -1.0, -1.0,
      1.0, -1.0,
    ];
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(positions), this.gl.STATIC_DRAW);

    const textureCoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, textureCoordBuffer);
    const textureCoordinates = [
      0.0, 0.0,
      1.0, 0.0,
      0.0, 1.0,
      1.0, 1.0,
    ];
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), this.gl.STATIC_DRAW);

    const aVertexPosition = this.gl.getAttribLocation(this.program, "aVertexPosition");
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);
    this.gl.vertexAttribPointer(aVertexPosition, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(aVertexPosition);

    const aTextureCoord = this.gl.getAttribLocation(this.program, "aTextureCoord");
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, textureCoordBuffer);
    this.gl.vertexAttribPointer(aTextureCoord, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(aTextureCoord);
  }

  public render(textCanvas: HTMLCanvasElement, now: number, config: CRTEffectConfiguration): void {
    this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.gl.useProgram(this.program);

    const uTime = this.gl.getUniformLocation(this.program, "uTime");
    this.gl.uniform1f(uTime, now * 0.001);

    const uResolution = this.gl.getUniformLocation(this.program, "uResolution");
    this.gl.uniform2f(uResolution, this.canvas.width, this.canvas.height);

    const uTextTexture = this.gl.getUniformLocation(this.program, "uTextTexture");
    this.gl.uniform1i(uTextTexture, 0);

    const uBackgroundColor = this.gl.getUniformLocation(this.program, "uBackgroundColor");
    this.gl.uniform3f(uBackgroundColor,
      config.backgroundColor[0] / 255,
      config.backgroundColor[1] / 255,
      config.backgroundColor[2] / 255
    );

    const uScanlinePeriod = this.gl.getUniformLocation(this.program, "uScanlinePeriod");
    this.gl.uniform1f(uScanlinePeriod, config.scanlinePeriod);

    const uScanlineWidth = this.gl.getUniformLocation(this.program, "uScanlineWidth");
    this.gl.uniform1f(uScanlineWidth, config.scanlineWidth);

    const uScanlineIntensity = this.gl.getUniformLocation(this.program, "uScanlineIntensity");
    this.gl.uniform1f(uScanlineIntensity, config.scanlineIntensity);

    const uScanlineFadeHeight = this.gl.getUniformLocation(this.program, "uScanlineFadeHeight");
    this.gl.uniform1f(uScanlineFadeHeight, config.scanlineFadeHeight);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, textCanvas);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
  }
}
interface CRTEffectConfiguration {
  rows: number;
  cols: number;
  fontSize: number;
  fontWeight: string;
  fontFamily: string;
  textColor: string;
  backgroundColor: [r: number, g: number, b: number]; // RGB values
  cursorChar: string;
  cursorBlinkRate: number;
  typingSpeed: { min: number; max: number } | number;
  screenPadding: number;
  scrollOffCols: number
  scanlineFadeHeight: number; // Height of the fade effect (0.0 to 1.0)
  scanlinePeriod: number; // in seconds
  scanlineWidth: number; // relative to screen height (0.0 to 1.0)
  scanlineIntensity: number; // 0.0 to 1.0
  audioElement?: HTMLAudioElement;
}

const defaultConfig: CRTEffectConfiguration = {
  rows: 32,
  cols: 60,
  scrollOffCols: 4,
  fontSize: 20,
  fontWeight: 'bold',
  fontFamily: '"Press Start 2P", monospace',
  textColor: '#39ff14',
  backgroundColor: [0, 20, 0], // Dark green
  scanlinePeriod: 15, // loop every 10 seconds
  scanlineWidth: 0.015, // 1% of screen height
  scanlineIntensity: 0.6, // Moderate intensity
  scanlineFadeHeight: 0.01, // Fade over 30% of the screen height
  cursorChar: '_', // '█',
  cursorBlinkRate: 500,
  typingSpeed: 50,
  screenPadding: 20,
};

class CRTEffectText {
  private webgl: CRTEffectWebGL;
  private textCanvas: HTMLCanvasElement;
  private textCtx: CanvasRenderingContext2D;
  private config: CRTEffectConfiguration;
  private charWidth: number;
  private charHeight: number;
  private text: string[] = [];
  private currentRow: number = 0;
  private currentCol: number = 0;
  private typingQueue: Array<(() => void) | string> = [];
  private isTyping: boolean = false;
  private cursorVisible: boolean = true;
  private cursorBlinkInterval: ReturnType<typeof setInterval>;

  constructor(private canvas: HTMLCanvasElement, config: Partial<CRTEffectConfiguration> = {}) {
    this.config = { ...defaultConfig, ...config };
    this.webgl = new CRTEffectWebGL(canvas);
    this.setupTextCanvas();
    this.animate(0);
    this.cursorBlinkInterval = setInterval(() => {
      this.cursorVisible = !this.cursorVisible;
    }, this.config.cursorBlinkRate);
  }

  private setupTextCanvas(): void {
    this.textCanvas = document.createElement("canvas");
    this.textCanvas.width = this.canvas.width;
    this.textCanvas.height = this.canvas.height;

    this.textCtx = this.textCanvas.getContext("2d")!;

    // Calculate rows and cols based on fixed font size
    this.charWidth = this.config.fontSize;
    this.charHeight = this.config.fontSize * 1.5;

    this.config.cols = Math.floor((this.textCanvas.width - 2 * this.config.screenPadding) / this.charWidth);
    this.config.rows = Math.floor((this.textCanvas.height - 2 * this.config.screenPadding) / this.charHeight);

    this.textCtx.font = `${this.config.fontWeight} ${this.config.fontSize}px ${this.config.fontFamily}`;
    this.textCtx.fillStyle = this.config.textColor;
    this.textCtx.textAlign = "left"; // Changed to left alignment
    this.textCtx.textBaseline = "top"; // Changed to top alignment
  }

  private updateTextTexture(): void {
    this.textCtx.fillStyle = `rgb(${this.config.backgroundColor[0]}, ${this.config.backgroundColor[1]}, ${this.config.backgroundColor[2]})`;
    this.textCtx.fillRect(0, 0, this.textCanvas.width, this.textCanvas.height);

    for (let i = 0; i < this.text.length; i++) {
      for (let j = 0; j < this.text[i].length; j++) {
        const x = this.getXPosition(j);
        const y = this.getYPosition(i);
        this.drawGlowingChar(this.text[i][j], x, y);
      }
    }

    // Draw blinking cursor
    if (this.cursorVisible) {
      const cursorX = this.getXPosition(this.currentCol);
      const cursorY = this.getYPosition(this.currentRow);
      this.drawGlowingChar(this.config.cursorChar, cursorX, cursorY);
    }
  }

  private getXPosition(col: number): number {
    return this.config.screenPadding + col * this.charWidth;
  }

  private getYPosition(row: number): number {
    return this.config.screenPadding + row * this.charHeight;
  }

  private drawGlowingChar(char: string, x: number, y: number): void {
    // Enhanced glow effect
    this.textCtx.shadowColor = this.config.textColor;
    this.textCtx.shadowBlur = 10;
    this.textCtx.fillStyle = this.config.textColor;

    // Draw the character multiple times for a stronger glow
    for (let i = 0; i < 5; i++) {
      this.textCtx.fillText(char, x, y);
    }

    // Reset shadow for the main character
    this.textCtx.shadowBlur = 0;
    this.textCtx.fillStyle = '#ffffff';
    this.textCtx.fillText(char, x, y);
  }

  private animate(now: number): void {
    this.updateTextTexture();
    this.webgl.render(this.textCanvas, now, this.config);
    requestAnimationFrame(this.animate.bind(this));
  }

  public clear(): void {
    this.text = [];
    this.currentRow = 0;
    this.currentCol = 0;
    this.typingQueue = [];
    this.isTyping = false;
  }

  type(newText: string, withSound = false): Promise<void> {
    this.config.audioElement?.play().catch((error) => {
      console.error('Failed to play audio:', error);
    })
    return new Promise<void>((resolve) => {
      this.typingQueue.push(...newText.split(''));
      if (!this.isTyping) {
        this.typeNextChar(resolve);
      } else {
        this.typingQueue.push(() => resolve());
      }
    }).then(() => {
      this.config.audioElement?.pause();
    });
  }

  private typeNextChar(resolve?: () => void): void {
    this.isTyping = true;
    if (this.typingQueue.length > 0) {
      const char = this.typingQueue.shift()!;

      if (typeof char === 'function') {
        char();
        this.typeNextChar(resolve);
        return;
      }

      if (char === '\n' || this.currentCol >= this.config.cols) {
        this.currentRow++;
        this.currentCol = 0;
      }

      if (this.currentRow + this.config.scrollOffCols >= this.config.rows) {
        this.text.shift();
        this.currentRow--;
      }

      if (!this.text[this.currentRow]) {
        this.text[this.currentRow] = '';
      }

      if (char !== '\n') {
        this.text[this.currentRow] += char;
        this.currentCol++;
      }

      setTimeout(
        () => this.typeNextChar(resolve),
        this.getTextDelay()
      );
    } else {
      this.isTyping = false;
      if (resolve) resolve();
    }
  }
  private getTextDelay() {
    if (typeof this.config.typingSpeed === 'number') {
      return this.config.typingSpeed;
    }

    return this.config.typingSpeed.min + Math.random() * (this.config.typingSpeed.max - this.config.typingSpeed.min);
  }
  public updateConfig(newConfig: Partial<CRTEffectConfiguration>): void {
    this.config = { ...this.config, ...newConfig };
    this.webgl = new CRTEffectWebGL(this.canvas);
    this.setupTextCanvas();
  }

  public waitForInput(): Promise<void> {
    return new Promise<void>((resolve) => {
      const listener = () => {
        resolve();
        window.removeEventListener('keydown', listener);
        window.removeEventListener('click', listener);
      }

      window.addEventListener('keydown', listener);
      window.addEventListener('click', listener);
    });
  }
}
function calculateDimensions(canvas: HTMLCanvasElement, fontSize: number): { rows: number; cols: number } {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const charWidth = fontSize;
  const charHeight = fontSize * 1.5;

  const cols = Math.floor((screenWidth - 2 * defaultConfig.screenPadding) / charWidth);
  const rows = Math.floor((screenHeight - 2 * defaultConfig.screenPadding) / charHeight);

  return { rows, cols };

}

// Initialize the CRT effect when the page loads
window.onload = async () => {
  const introImage = document.getElementById("intro") as HTMLImageElement;

  introImage.addEventListener('click', () => {
    introImage.remove()
  }, { once: true });

  const canvas = document.getElementById("canvas") as HTMLCanvasElement;

  const audio = new Audio(process.env.PUBLIC_URL + '/typing.mp3');
  audio.loop = true;
  audio.volume = 0.1
  audio.load()

  const fontSize = 20;

  const crtEffect = new CRTEffectText(canvas, {
    audioElement: audio,

    ...calculateDimensions(canvas, fontSize)
  });

  window.addEventListener('resize', () => {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    crtEffect.updateConfig(
      calculateDimensions(canvas, fontSize)
    );
  })

  await crtEffect.waitForInput();

  for (const scene of story) {
    crtEffect.clear();

    const lines = scene
      .map((line, index, arr) => {
        const prev = arr[index - 1];
        const isLast = index === arr.length - 1;

        const prevEndsWithWhitespace = prev && (prev.endsWith(" ") || prev.endsWith("\n"));
        return {
          line: index % 2 === 1 && !prevEndsWithWhitespace ? " " + line : line,
          index,
          isLast,
        };
      });

    for (const { line, isLast } of lines) {
      await crtEffect.type(line)

      if (isLast) {
        await crtEffect.waitForInput();
      }
    }
  }

  // await sleep(1000);
};