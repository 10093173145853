export type Scene = ReadonlyArray<string>;

export type Story = ReadonlyArray<Scene>;

const Day1: Scene = [
  "Day 1:\n\n",
  "I don't know my name, but Jon will do. A fitting name for a John Doe, wouldn't you say?\n\n",

  "I woke up six hours ago, or so I think. Time is a blur when you've been asleep for… how long ? Decades ? Centuries ? Millennia ?\n\n",

  "The cryostasis pod wasn't meant to wake me up like this. It wasn't gentle. It wasn't planned. Debris crashed through the glass shell, jarring me back to a world I barely recognize.\n\n",

  "The place is a mess. Emergency lights barely work, giving everything a creepy, dim look. Used to be pristine.\n\n",

  "Now it's a tomb. Dust everywhere. Equipment broken. Feels like nobody's been here for years. Every step I take echoes. Alone. At least, I hope I am.\n\n",

  "I stumbled upon a tablet, an ancient relic of the past, just like me, yet it works. It's a lifeline to record my thoughts, my experiences.\n\n",

  "Maybe someone will find this. Maybe no one will. But I need to make sense of this chaos, to remember who I am or was.\n\n",

  "The outside world is a mystery. The windows are reinforced, unyielding. I can see glimpses of a frozen landscape, harsh and unforgiving.\n\n",

  "Svalbard, I think. Maybe near the seed vault. But everything is so… wrong. It's like waking up in a nightmare, where nothing makes sense, and the rules have changed.\n\n",

  "There's a faint glow on the horizon, as if the sun is about to rise, though my calculations say it shouldn't for another two days. The sun isn't there yet; it's still too dark. I'm fed up with this frozen nightmare and desperate to see the sun again, so I checked when it would rise.\n\n",

  "I need to keep moving, keep exploring. There must be answers here somewhere. A way to find out what went wrong, what happened to everyone else. For now, I'll rest. Gather my strength. Tomorrow, I'll venture deeper into this forsaken place.\n\n",

  "End of log. 49 Hours and 32 minutes til sunrise."
]

const Day2: Scene = [
  "Day 2:\n\n",

  "I'm exhausted. Sleeping on the cold, hard ground is no way to rest, but what choice do I have? Every sound startles me awake. I keep thinking I hear something, someone. But it's just the building settling, or maybe my own paranoia.\n\n",

  "I spent most of the day exploring further. The tablet has some log files—most of them are corrupted, but I managed to read snippets. They mention the sun and cosmic rays. I have no idea what they're talking about.\n\n",

  "Then there's Project Orion. Nuclear propulsion? Surely no one would be desperate enough to use something so dangerous. But maybe they were. Maybe that's why everything's gone to hell.\n\n",

  "I saw warnings and drawings of the sun on the walls, almost like they were trying to communicate something important. In one section of the facility, I found animal carcasses and cages. It seems they were performing experiments on them, but what they were trying to achieve is beyond me.\n\n",

  "Today was uneventful, just more questions and no answers. Tomorrow, I'm going to try to escape. I need to see the outside world for myself, even if it's just to confirm that it's as bleak as it seems.\n\n",

  "End of log. 24 Hours and 15 minutes until sunrise."
]

const Day3: Scene = [
  "Day 3:\n\n",

  "The cold floor and restless sleep are becoming routine. I forced myself to keep exploring today, hoping for a breakthrough.\n\n",

  "Finally, I found a map of the facility. It shows the way to the exit. Relief washed over me, but it was short-lived. The food I found won't last a week. I have to plan my escape carefully. I can't survive the frozen landscape unprepared.\n\n",

  "Once the sun rises tomorrow, it will stay up for four months straight—midnight sun, they call it. This should give me some warmth and a better chance to survive. For now, I'll keep searching for anything useful.\n\n",

  "While rummaging through another part of the facility, I stumbled upon a dog. It's dirty and looks more like a bag of bones than a living creature. Still, it's alive.\n\n",

  "I shared some of my food with it and decided to call it “Sunshine.” It's like a tiny ray of hope in this forsaken place.\n\n",

  "After feeding Sunshine, it started to lead me somewhere. Nervously at first, but with increasing confidence, it took me to a hidden room.\n\n",

  "It seems like Sunshine trusts me, using this hidden space as a home. Inside, I found more notes about the facility's past.\n\n",

  "They were performing gene editing, trying to make people live forever—chasing the fountain of youth. The facility was originally created by Larry E. Later, the military took over and shifted the focus to radiation hardening of living organisms.\n\n",

  "It's all starting to make a twisted kind of sense.\n\n",

  "Sunshine seems to have perked up a bit with the food. Maybe there's hope for both of us yet.\n\n",

  "I'll continue exploring and gathering supplies. Every bit of information, every piece of equipment could mean the difference between life and death.\n\n",

  "End of log. 9 Hours and 30 minutes until sunrise."
]



export const story: Story = [
  Day1,
  Day2,
  Day3,
]